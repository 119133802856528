import React,{useCallback} from 'react';

const PortfolioCategory = (
    {
        category
    }
) => {

    const handleLink = useCallback( (e,url) => {
        e.preventDefault();
        if (url) {
            window.open(url,"_blank")
        }
    },[]);

    return (
        <div className="col col-xs-12 col-sm-6 portfolio__category">
            <h3>
                { category.portfolio_kategoria_nazwa}
            </h3>
            <ul>
                {
                    category &&
                    category.portfolio_kategoria_projekty.map( (item,index) =>
                        <li
                            key={index}
                            className="portfolio__category__item"
                            onClick={ e => handleLink(e,item.portfolio_pozycja_link)}
                        >
                            {
                                item.portfolio_pozycja_miniatura &&
                                <img
                                    className="portfolio__category__item__thumb"
                                    src={item.portfolio_pozycja_miniatura}
                                    alt={`Pic: ${item.portfolio_pozycja_tytul}`} />
                            }
                            <p>
                                <span className="portfolio__category__item__title">
                                    {item.portfolio_pozycja_tytul}
                                </span>
                                {
                                    item.portfolio_pozycja_rok &&
                                    <span className="portfolio__category__item__meta">
                                        {item.portfolio_pozycja_rok}
                                    </span>
                                }
                                {
                                    item.portfolio_pozycja_kraj &&
                                    <span className="portfolio__category__item__meta">
                                        {item.portfolio_pozycja_kraj}
                                    </span>
                                }

                            </p>
                        </li>
                    )
                }

            </ul>
        </div>
    );
};

export default PortfolioCategory;