import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";

//actions
import {
    setPageData,
    setPageOptions,
    setPageOptionsPortfolio,
    toggleLoader,
    toggleHomeLogoOverlay
} from "../../actions/pageActions";

// components
import Header from '../Header';
import Loading from "../Loading";

//config
import { config } from "../../config";

class Page extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: []
        };
        this.handleLoader = this.handleLoader.bind(this);
    }
    handleLoader() {
        this.props.showLoader(false);
    }

    componentDidMount(props) {
        const {
            pageIndex,
            dispatchPageData,
            showLoader
        } = this.props;
        showLoader(true);
        axios
            .get(
                config.domainRelPath+'/wp-json/acf/v3/pages/?per_page=30'
            )
            .then(res => {
                dispatchPageData(res.data[pageIndex].acf);
                showLoader(false);
            })
            .catch(error => console.log(error));

        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-baseone')
            .then( res => { this.props.dispatchPageDataOptions( res.data.acf ) } )
            .catch(error => console.log(error));

        axios
            .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-baseone-portfolio')
            .then( res => { this.props.dispatchPageDataPortfolio( res.data.acf ) } )
            .catch(error => console.log(error));


    }

    render() {
        const { url } = this.props;


        return (
            <Fragment>
                <Loading />
                <Header post={false} url={url} />
                {this.props.children}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    pageName: state.router.location.hash.replace("#","")
});

const mapDispatchToProps = dispatch => ({
    showLoader: value => dispatch(toggleLoader(value)),
    dispatchPageData: data => dispatch( setPageData(data) ),
    dispatchPageDataOptions: data => dispatch( setPageOptions(data) ),
    dispatchPageDataPortfolio: data => dispatch( setPageOptionsPortfolio(data) ),
});

export default connect(mapStateToProps,mapDispatchToProps)(Page);

