import React,{useRef,useEffect,useState} from 'react';

const Doodle = ({mainColor}) => {

    const [lineLength,setLineLength] = useState(null);

    const lineRef = useRef(null);

    useEffect( () => {
        const line = lineRef.current;
        setLineLength(line.getTotalLength());
    },[setLineLength]);


    return (
        <svg version="1.1" id="svg_doodle"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="119.27px" height="44.81px"
             viewBox="0 0 119.27 44.81" >
            <g>
                <path
                    ref={lineRef}
                    fill="none"
                    stroke={mainColor}
                    strokeLinecap="round"
                    d="M64.92,0.53c-20.15,0-64.24-1.75-64.24,23.9
                    c0,20.59,45.44,19.92,60.86,19.92c6.69,0,13.79,0.66,20.29-0.78c6.53-1.44,14.97-2.95,20.85-5.75c7.35-3.5,20.29-8.29,14.59-18.37
                    C108.13,3.3,84.07,1.18,63.11,0.91"/>
            </g>
        </svg>

    );
};

export default Doodle;