import React,{Fragment,useEffect} from 'react';
import {connect} from "react-redux";

//svg
import Logo from "../svg/Logo";

const Loading = ( {
                      displayLoader,
                      displayHomeLogoOverlay
} ) => {
    return (
        <Fragment>
            {
                displayLoader &&
                !displayHomeLogoOverlay &&
                <div className="overlay-loader">
                    <div className="el" >
                        <Logo />
                    </div>
                </div>
            }

        </Fragment>

    );
};

const mapStateToProps = state => ({
    displayLoader: state.page.displayLoader,
    displayHomeLogoOverlay: state.page.displayHomeLogoOverlay
});

export default connect(mapStateToProps,null)(Loading);