import React from 'react';
import {connect} from "react-redux";

//components
import Post from "../../Post";
import Footer from "../../Footer";
import Print from "../../svg/Print";
import ServiceGallery from "../Home/Sections/Services/Category/ServiceGallery";


const OfferItem = (
    {
        match,
        pageData,
        pageOptions
    }) => {

    const {
        pozycja_banner,
        nazwa_pozycji,
        pozycja_pdf,
        pozycja_opis,
        pozycja_specs,
        pozycja_features,
        pozycja_galeria,
        pozycja_plan
    } = pageData;

    return (
        <Post
            postId={match.params.offerItemId}
            postCatId={match.params.offerCategoryId} >
            <main className="page post offer-item">
                <article className="boxed">
                    <div className="row offer-item__banner">
                        <div className="col col-xs-12">
                            <img
                                src={pozycja_banner}
                                alt={`Banner: ${nazwa_pozycji}`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12 col-sm-6 services__category__item__info">
                            <div className="row">
                                <div className="col col-xs-12 services__category__item__info__title">
                                    <h4>{nazwa_pozycji}</h4>
                                    <nav>
                                        <ul>
                                            <li>
                                                <a href={pozycja_pdf ? pozycja_pdf : "#"} target="_blank">
                                                    <Print mainColor="#FFFFFF" />
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-xs-12 services__category__item__info__description">
                                    <p dangerouslySetInnerHTML={{__html:pozycja_opis}}/>
                                </div>
                            </div>
                            <div className="row services__category__item__info__specs">
                                <div className="col col-xs-4">
                                    <h5>{pageOptions.loc_specyfikacje}</h5>
                                    <article dangerouslySetInnerHTML={{__html:pozycja_specs}} />
                                </div>
                                <div className="col col-xs-8">
                                    <h5>{pageOptions.loc_funkcje}</h5>
                                    <article dangerouslySetInnerHTML={{__html:pozycja_features}} />
                                </div>
                            </div>
                        </div>

                        <ServiceGallery
                            post
                            accentColor={pageOptions.opt_akcent_glowny}
                            gallery={pozycja_galeria}
                        />
                    </div>
                    {
                        pozycja_plan &&
                        <div className="row offer-item__plan">
                            <div className="col col-xs-12">
                                <img
                                    src={pozycja_plan}
                                    alt={`Plan: ${nazwa_pozycji}`}
                                />
                            </div>
                        </div>
                    }

                </article>
                <Footer />
            </main>
        </Post>
    );

}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(OfferItem);