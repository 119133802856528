import React from 'react';

const ArrowHorizontal = ({mainColor}) => {
    return (
        <svg width="51" height="21"
             viewBox="0 0 51 21"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path stroke={mainColor} d="M1 1L26 19.5L50 1" />
        </svg>


    );
};

export default ArrowHorizontal;