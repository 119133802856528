import React from 'react'
import { Route, Switch } from 'react-router';
import Home from '../components/Views/Home';
import PortfolioItem from '../components/Views/OfferItem';

import { paths } from '../config/paths';

const routes = (
    <Switch>
        <Route exact path={paths.home} component={Home} />
        <Route path={paths.offerItem} component={PortfolioItem} />
    </Switch>
);

export default routes;