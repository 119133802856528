import React,{useEffect} from 'react';
import {connect} from "react-redux";

//components
import ContactEmails from "../ContactEmails";
import ContactPhones from "../ContactPhones";

//svg


const Footer = props => {
    const {
        pageOptions
    } = props;

    return (
        <>
            <div className="row"  id="contact" />
            <footer className="main-footer boxed">
                <div className="row main-footer__logo">
                    <div className="col col-xs-12 col-sm-3 center-xs start-sm">
                        <img
                            src={pageOptions.opt_logo}
                            alt="Baseone - logo"
                        />
                    </div>
                    <div className="col col-xs-12 col-sm-9"/>
                </div>
                <div className="row main-footer__contact-data top-xs center-xs start-sm">
                    <div className="col col-xs-12 col-sm-2">
                        <p className="bold">{pageOptions.opt_nazwa_firmy}</p>
                    </div>
                    <div className="col col-xs-12 col-sm-2">
                        <ContactEmails />
                    </div>
                    <div className="col col-xs-12 col-sm-3">
                        <ContactPhones footer />
                    </div>
                    <div className="col col-xs-12 col-sm-3 main-footer__contact-data__address">
                        <p dangerouslySetInnerHTML={{__html:pageOptions.opt_adres_firmy}}/>
                    </div>
                    <div className="col col-xs-12 col-sm-2">
                        <p>
                            NIP: {pageOptions.opt_nip}<br/>
                            KRS: {pageOptions.opt_krs}
                        </p>
                    </div>
                </div>
                <div className="row main-footer__copyrights center-xs start-sm">
                    <div className="col col-xs-12 col-sm-6 main-footer__copyrights__note">
                        <p>{pageOptions.opt_copy}</p>
                    </div>
                    <div className="col col-xs-12 col-sm-6 main-footer__copyrights__line"/>
                </div>

                {
                    pageOptions.opt_regulaminy &&
                    pageOptions.opt_regulaminy.length > 0 &&
                        <ul className="row main-footer__legal center-xs end-sm">
                            {
                                pageOptions.opt_regulaminy.map( (doc,index) =>
                                    <li
                                        key={index}
                                        className="col col-xs-12 col-sm-2"
                                    >
                                        <a
                                            href={doc.opt_regulaminy_link}
                                            title={`Doc: ${doc.opt_regulaminy_nazwa}`}
                                            target="_blank"
                                            >
                                            {doc.opt_regulaminy_nazwa}
                                        </a>
                                    </li>
                                )
                            }
                        </ul>
                }

            </footer>
        </>
    );
};

const mapStateToProps = (state) => ({
    pageOptions: state.page.pageOptions
});


export default connect(mapStateToProps,null)(Footer);