import React from 'react';
import { connect } from "react-redux";
import classnames from "classnames";

const ContactPhones = ({pageOptions,footer}) => {
    return (
        <div className="row contact__data contact__data__phones center-xs start-sm">
            <div className={classnames(
                "col col-xs-6 contact__data__label",{
                    "col-sm-4": footer
                }
            )}>
                {pageOptions.opt_tel_etykieta}:
            </div>
            <div className={classnames(
                "col col-xs-12 col-sm-6 contact__data__values",{
                    "col-sm-6" : !footer,
                    "col-sm-8" : footer
                }
            )}>
                {
                    pageOptions.opt_kontakt_telefony &&
                    pageOptions.opt_kontakt_telefony.map(
                        (tel,index) =>
                            <a
                                key={index}
                                href={`tel:${tel.numer_telefonu.replace(/[() ]/g,'')}`} title={tel.numer_telefonu_nazwa}
                            >
                                {tel.numer_telefonu}
                            </a>

                    )
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    pageOptions: state.page.pageOptions
});

export default connect(mapStateToProps,null)(ContactPhones);