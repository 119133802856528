import React from 'react';

const Info = ({mainColor}) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={mainColor} d="M4.26349 3H14.7364C15.4326 3 16 3.56755 16 4.26348V14.7365C16 15.4324 15.4324 16 14.7364 16H4.26349C3.56758 16 3 15.4325 3 14.7365V4.26348C3 3.56758 3.56756 3 4.26349 3V3ZM9.50006 5.50298C9.97846 5.50298 10.3663 5.89082 10.3663 6.36921C10.3663 6.84761 9.97846 7.23545 9.50006 7.23545C9.02148 7.23545 8.63381 6.84761 8.63381 6.36921C8.63381 5.89082 9.02148 5.50298 9.50006 5.50298ZM10.3237 8.92492V12.6734C10.3237 13.7629 8.6765 13.7629 8.6765 12.6734V8.92492C8.6765 7.83543 10.3237 7.83543 10.3237 8.92492V8.92492ZM14.7365 4.0733H4.26358C4.16003 4.0733 4.07331 4.15987 4.07331 4.26342V14.7365C4.07331 14.8399 4.16003 14.9266 4.26358 14.9266H14.7365C14.8402 14.9266 14.9269 14.8399 14.9269 14.7365V4.26342C14.9269 4.15987 14.8402 4.0733 14.7365 4.0733V4.0733Z"/>
        </svg>

    );
};

export default Info;