export const  handleMenuHash = (
    domain,
    e,
    url,
    post,
    toggleMenu,
    parentUrl,
    setService
) => {
    e.preventDefault();
    toggleMenu(false);
    if(parentUrl) {
        setService({
            filter: url ? url.split("=")[1] : null,
            category: url ? url.split("?")[0].replace("#","") : null
        });
    }
    if (!post) {
        const el = document.getElementById(url.split('?')[0].replace('#',''));
        const posY = el.offsetTop;
        const posYoffset = url === "#contact" ? 31 : 134;
        window.scroll({
            top: posY+posYoffset,
            left:0,
            behavior: "smooth"
        });
        toggleMenu(false);
    } else {
        window.open(domain + url,"_self")
    }
};
