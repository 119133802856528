import React from 'react';
import Doodle from "../../../../../../svg/Doodle";

import classnames from "classnames";

const TopBar = (
    {
        servicesMainTitle,
        accentColor,
        category,
        setActiveItem,
        activeItem,
        setActiveItemID,
        activeItemID
    }) => {
    return (
        <div className="row services__category__topbar" >
            <div className="col col-xs-12 col-sm-4">
                <h2>{servicesMainTitle}</h2>
                <h3>{category.nazwa_kategorii}</h3>
            </div>

            <div className="col col-xs-12 col-sm-8">
                <ul className="row">
                    {
                        category.lista &&
                        category.lista.map( (item,index) =>
                            <li
                                data-item-id={item.id_pozycji}
                                className={classnames(
                                    "col col-xs-12 col-sm-4 col-md-3",{
                                        "active": activeItemID ? activeItemID === item.id_pozycji : activeItem === index
                                    }
                                )}
                                key={index}
                                onClick={() => {
                                    setActiveItem(index);
                                    setActiveItemID(item.id_pozycji)
                                }}
                            >
                                <span>{item.nazwa_pozycji}</span>
                                {
                                    ( activeItemID ? activeItemID === item.id_pozycji : activeItem === index )  &&
                                    <Doodle mainColor={accentColor}/>
                                }

                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    );
};

export default TopBar;