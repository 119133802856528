import React, {Fragment} from 'react';

//components
import NextSectionNav from "../../NextSectionNav";
import Category from "./Category";

const Services = (
    {
        servicesMainTitle,
        servicesMenuID,
        list
    }) => {
    return (
        <Fragment>
            <NextSectionNav
                nextSectionID={servicesMenuID}
                nextSectionName={servicesMainTitle}
                scrollOffset={136}
            />
            <div
                id={servicesMenuID}
                className="section services row center-xs start-sm" >
                <div className="col col-xs-12">
                    {
                        list &&
                        list.map( (category,index) =>
                            <Category
                                servicesMainTitle={servicesMainTitle}
                                category={category}
                                key={index} />
                        )

                    }
                </div>
            </div>
        </Fragment>
    );
};

export default Services;