import React from 'react';

const Logo = () => {
    return (
        <svg
            width="72" height="52"
            viewBox="0 0 72 52" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_30_103)">
                <rect x="-41" y="-18" width="165" height="89" fill="#FBF8EE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M13.1779 24.7757L34.8689 0.0356394L21.781 0L0 24.935L13.1779 24.7757Z" fill="#0000FF"/>
                <path d="M47.0234 21.6164C41.9046 21.6164 38.5793 22.1656 37.0725 23.2809C35.5658 24.3962 34.7956 26.8218 34.7956 30.5598C34.7956 35.0336 35.48 37.9099 36.8381 39.2076C38.1963 40.5053 41.2077 41.1677 45.9017 41.1677C52.5273 41.1677 56.449 40.2369 57.6942 38.3753C58.5166 37.1635 58.9268 34.6017 58.9268 30.7296C58.9268 26.7463 58.2404 24.2118 56.8843 23.1824C55.5282 22.1531 52.2405 21.6164 47.0234 21.6164ZM34.8689 0.0377502V18.0671C36.1141 15.4004 37.6355 13.6227 39.4289 12.7065C41.9541 11.4235 46.0998 10.7792 51.866 10.7736C60.7949 10.7736 66.5101 12.457 69.0116 15.8239C71.0039 18.4612 72 24.0776 72 32.6394C72 40.3851 70.639 45.5486 67.9171 48.13C65.1952 50.7114 59.7506 52.0014 51.5834 52C45.9429 52 42.0748 51.4829 39.9793 50.4487C37.8838 49.4144 36.1817 47.1579 34.8731 43.6793V51.1551H21.9464V14.7778L34.8689 0.0377502Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_30_103">
                    <rect width="72" height="52" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Logo;