import React,{useState} from 'react';
import { connect } from "react-redux";

//tools
import classnames from "classnames";

const LanguageSwitch = ({pageOptions}) => {
    const [active, setActive] = useState(null);
    const languages = pageOptions.opt_jezyki_lista;
    return (
        <nav className="lang-switch">
            <ul>
                {
                    languages &&
                    languages.map( (language,index) => {
                        return(
                            <li
                                key={index}
                                className={ classnames({
                                    "default" : language.default,
                                    "active" : index === active
                                })}
                                onMouseOver={ () => setActive(index) }
                            >
                                <a
                                    href={language.lang_url}
                                    title={language.lang_pelna_nazwa}>
                                    {language.lang_id}
                                </a>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    );
};

const mapStateToProps = state => ({
    pageOptions: state.page.pageOptions
});

export default connect(mapStateToProps,null)(LanguageSwitch);