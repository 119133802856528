import React, {useEffect,Fragment} from 'react';
import {connect} from "react-redux";

//actions
import {
    setPageData,
    setPageOptions,
    toggleLoader
} from "../../actions/pageActions";

// components
import Header from '../Header';
import Loading from "../Loading";

//tools
import axios from "axios";

//config
import {config} from "../../config";

const Post = ({
    children,
    dispatchPageData,
    dispatchPageDataOptions,
    postCatId,
    postId,
    showLoader,
    match
}) => {

    const itemId = postId ? postId : match.params.offerItemId;
    const catId = postCatId ? postCatId : match.params.offerCategoryId;

    useEffect(
        () => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto'
            });

            showLoader(true);

            axios
                .get(config.domainRelPath+'/wp-json/acf/v3/pages/?per_page=30')
                .then(
                    res => {
                        const cat = res.data[0].acf.services_lista_kategorii.find( item => item.id_kategorii === catId);
                        const serviceData = cat.lista.find(service => service.id_pozycji === itemId)
                        dispatchPageData(serviceData);
                        showLoader(false);
                    }
                )
                .catch(error => console.log(error));

            axios
                .get(config.domainRelPath+'/wp-json/acf/v3/options/acf-options-baseone')
                .then(
                    res => {
                        dispatchPageDataOptions( res.data.acf )
                    }
                )
                .catch(error => console.log(error));

        },[
            dispatchPageData,
            dispatchPageDataOptions
        ]
    );
    
    return (
        <Fragment>
            <Loading />
            <Header post={true}/>
            {children}
        </Fragment>
    );
    
};

const mapDispatchToProps = dispatch => ({
    dispatchPageData: data => dispatch( setPageData(data) ),
    dispatchPageDataOptions: data => dispatch( setPageOptions(data) ),
    showLoader: value => dispatch(toggleLoader(value))
});

export default connect(null,mapDispatchToProps)(Post);

