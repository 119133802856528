import React from 'react';
import {handleMenuHash} from "../menuUtils";
import Doodle from "../../../svg/Doodle";

import {config} from "../../../../config";

const MenuTopLink = (
    {
        setHoveredMenuItem,
        toggleMenu,
        item,
        accentColor,
        displayDoodle,
        post
    }) => {
    return (
        <a
            href={item.url}
            onMouseEnter={ () => {
                setHoveredMenuItem(item.ID)
            }  }
            onClick={ e => handleMenuHash(
                config.domain + config.domainRelPath,
                e,
                item.url,
                post,
                toggleMenu
            ) } >
                <span className="span-link">
                    {item.title}
                </span>

            {
                displayDoodle &&
                <Doodle mainColor={accentColor} />
            }


        </a>
    );
};

export default MenuTopLink;