import React,{Fragment} from 'react';

//tools
import classnames from "classnames";

//svg
import SlashVertical from "../../../../svg/SlashVertical";

const SectionAboutUs = ({
    cover,
    coverMobile,
    coverMobileTransp,
    blend,
    txt1,
    txt2,
    txtPosition,
    txtColor,
                            isMobile
}) => {
    return (
            <div
                id="about-us"
                className={classnames(
                    "section",
                    "about-us",
                    "row",
                    "center-xs start-sm middle-xs"
                )}
                style={{
                    backgroundImage: isMobile ? `url(${coverMobile})` : "none"
                }}
            >
                <div
                    className="col col-xs-12"
                >

                    <figure>
                        <div
                            className="about-us__blenda"
                            style={{backgroundColor:blend}}
                        />
                        {
                            !isMobile &&
                            <img
                                className="about-us__cover"
                                src={cover}
                                width={1440}
                                height={882}
                            />
                        }

                    </figure>
                    <figcaption className="row middle-xs">
                        <div className="col col-xs-12">
                            {
                                txtPosition === "left" &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col col-xs-12 col-sm-6">
                                            <SlashVertical mainColor={txtColor}/>
                                            <p
                                                dangerouslySetInnerHTML={{__html:txt1}}
                                                style={{color:txtColor}}
                                                className="about-us__txt1"/>
                                        </div>
                                        <div className="col col-xs-12 col-sm-6"/>
                                    </div>
                                    {
                                        txt2 &&
                                        <div className="row">
                                            <div className="col col-xs-12 col-sm-2 col-md-3" />
                                            <div className="col col-xs-12 col-sm-4 col-md-3">
                                                <p
                                                    dangerouslySetInnerHTML={{__html:txt2}}
                                                    style={{color:txtColor}}
                                                    className="about-us__txt2"/>
                                            </div>
                                            <div className="col col-xs-12 col-sm-6"/>
                                        </div>
                                    }
                                </Fragment>
                            }
                            {
                                txtPosition === "right" &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col col-xs-12 col-sm-6"/>
                                        <div className="col col-xs-12 col-sm-6">
                                            <SlashVertical mainColor={txtColor}/>
                                            <p
                                                dangerouslySetInnerHTML={{__html:txt1}}
                                                style={{color:txtColor}}
                                                className="about-us__txt1"/>
                                        </div>
                                    </div>
                                    {
                                        txt2 &&
                                        <div className="row">
                                            <div className="col col-xs-12 col-sm-6"/>
                                            <div className="col col-xs-12 col-sm-2 col-md-3" />
                                            <div className="col col-xs-12 col-sm-4 col-md-3">
                                                <p
                                                    dangerouslySetInnerHTML={{__html:txt2}}
                                                    style={{color:txtColor}}
                                                    className="about-us__txt2"/>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            }
                            {
                                txtPosition === "middle" &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col col-xs-12 col-sm-2 col-md-3"/>
                                        <div className="col col-xs-12 col-sm-8 col-md-6">
                                            <SlashVertical mainColor={txtColor}/>
                                            <p
                                                dangerouslySetInnerHTML={{__html:txt1}}
                                                style={{color:txtColor}}
                                                className="about-us__txt1"/>
                                        </div>
                                        <div className="col col-xs-12 col-sm-2 col-md-3"/>
                                    </div>
                                    {
                                        txt2 &&
                                        <div className="row">
                                            <div className="col col-xs-12 col-sm-6 col-md-6" />
                                            <div className="col col-xs-12 col-sm-4 col-md-3">
                                                <p
                                                    dangerouslySetInnerHTML={{__html:txt2}}
                                                    style={{color:txtColor}}
                                                    className="about-us__txt2"/>
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            }

                        </div>
                    </figcaption>

                </div>

            </div>
    );
};

export default SectionAboutUs;