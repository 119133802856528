export const SET_HOVERED_MENU_ITEM = 'setHoveredMenuItemIndex';
export const SET_PAGE_DATA = 'setPageDataFromAcf';
export const SET_PAGE_OPTIONS = 'setPageOptionsFromAcf';
export const SET_PAGE_OPTIONS_PORTFOLIO = 'setPageOptionsFromAcfPortfolio';
export const SET_SERVICE = "setService";
export const TOGGLE_HOME_MENU = 'menuHomeToggleState';
export const TOGGLE_OVERLAY = 'routesToggleOverlay';
export const TOGGLE_LOADER = 'routesToggleLoader';
export const TOGGLE_HOME_LOGO_OVERLAY = 'toggleHomeLogoOverlay';

export const toggleHomeMenu = value => ({
    type: TOGGLE_HOME_MENU,
    value
});

export const toggleOverlay = value => ({
    type: TOGGLE_OVERLAY,
    value
});

export const toggleLoader = value => ({
    type: TOGGLE_LOADER,
    value
});

export const setPageData = data => ({
    type: SET_PAGE_DATA,
    data
});

export const setHoveredMenuItemIndex = value => ({
    type: SET_HOVERED_MENU_ITEM,
    value
});

export const setPageOptions = data => ({
    type: SET_PAGE_OPTIONS,
    data
});

export const setPageOptionsPortfolio = data => ({
    type: SET_PAGE_OPTIONS_PORTFOLIO,
    data
});

export const toggleHomeLogoOverlay = value => ({
    type: TOGGLE_HOME_LOGO_OVERLAY,
    value
});

export const setService = data => ({
    type: SET_SERVICE,
    data
});

