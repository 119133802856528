import React from 'react';

const PortfolioTopBar = ({id,categories}) => {
    return (
        <div
            id={id}
            className="portfolio__topbar"
        >
           <h2>
               {
                   categories &&
                   categories
                       .map( (cat,index) =>
                       <span key={index}>
                           {cat.portfolio_kategoria_nazwa}
                       </span>
                   )
               }
           </h2>
        </div>
    );
};

export default PortfolioTopBar;