import React from 'react';

const Print = ({mainColor}) => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5385 13.1905H17V4.80951H1V13.1905H3.46154" stroke={mainColor} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.28571 4.80952V1.63492C3.28571 1.28446 3.57016 1 3.92063 1H14.0794C14.4298 1 14.7143 1.28446 14.7143 1.63492V4.80952" stroke={mainColor} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.28571 10.1429H14.7143V17H3.28571V10.1429Z" stroke={mainColor} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.80951 11.6667H13.1905" stroke={mainColor} strokeMiterlimit="10" strokeLinejoin="round"/>
                <path d="M4.80951 14.7143H8.61903" stroke={mainColor} strokeMiterlimit="10" strokeLinejoin="round"/>
                <path d="M13.1905 7.09521H14.7143" stroke={mainColor} strokeMiterlimit="10" strokeLinejoin="round"/>
            </svg>

        </svg>
    );
};

export default Print;