import React from 'react';

const Partner = ({mainColor}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" width="28px" height="19px"
             viewBox="0 0 28 19" >
            <g>
                <path fill={mainColor} d="M27.72,4.93c0-0.41-0.12-0.82-0.39-1.16l-2.13-2.72c-0.36-0.45-0.9-0.68-1.44-0.68
                    c-0.32,0-0.65,0.08-0.94,0.26l-2.07,1.29c-0.22,0.14-0.59,0.23-0.95,0.23c-0.28,0-0.55-0.06-0.73-0.14l-2.03-0.98
                    c-0.33-0.16-0.69-0.22-1.06-0.22c-0.53,0-1.06,0.13-1.49,0.43L14.25,1.4c-0.19-0.09-0.48-0.24-0.81-0.4
                    c-0.39-0.19-0.86-0.27-1.32-0.27c-0.42,0-0.84,0.07-1.21,0.22L9.53,1.53C9.3,1.63,8.94,1.7,8.59,1.69c-0.36,0-0.71-0.06-0.94-0.16
                    L6.27,0.94C6.01,0.84,5.75,0.79,5.48,0.79c-0.31,0-0.61,0.06-0.9,0.18C4.29,1.08,4.03,1.25,3.82,1.49L1.3,4.31
                    C1.02,4.62,0.88,5.01,0.88,5.4c0,0.46,0.2,0.92,0.58,1.25l2.35,2.01L2.7,9.82c-0.31,0.32-0.46,0.74-0.46,1.15
                    c0,0.44,0.17,0.88,0.52,1.2l0.23,0.21c0.21,0.2,0.47,0.33,0.74,0.4c-0.13,0.25-0.21,0.52-0.21,0.79c0,0.44,0.17,0.88,0.52,1.2h0
                    l0.23,0.22c0.32,0.31,0.74,0.46,1.15,0.46c0.22,0,0.44-0.04,0.65-0.13c0.07,0.3,0.22,0.58,0.46,0.8l0.23,0.22
                    c0.32,0.31,0.74,0.46,1.15,0.46c0.32,0,0.65-0.09,0.93-0.28c0.08,0.26,0.22,0.51,0.43,0.71l0.23,0.22c0.32,0.3,0.74,0.46,1.15,0.46
                    c0.44,0,0.88-0.17,1.21-0.52l0.99-1.03l1.34,1.24c0.32,0.3,0.73,0.45,1.14,0.44c0.45,0,0.9-0.18,1.22-0.53l0.21-0.23
                    c0.18-0.2,0.3-0.43,0.37-0.68l0.52,0.48c0.32,0.3,0.73,0.44,1.14,0.44c0.45,0,0.9-0.18,1.23-0.53l0.21-0.23
                    c0.25-0.26,0.38-0.59,0.43-0.92c0.14,0.04,0.28,0.06,0.42,0.06c0.45,0,0.9-0.18,1.23-0.53l0.21-0.23c0.3-0.32,0.45-0.73,0.45-1.13
                    c0-0.13-0.02-0.25-0.05-0.37c0.37-0.05,0.73-0.21,1-0.51l0.21-0.23c0.3-0.32,0.45-0.73,0.45-1.13c0-0.45-0.18-0.89-0.53-1.22
                    l-0.4-0.37l3.61-3.99C27.57,5.85,27.72,5.38,27.72,4.93L27.72,4.93z M3.38,11.51c-0.16-0.15-0.23-0.35-0.23-0.55
                    c0-0.19,0.07-0.38,0.21-0.52l2.28-2.38C5.79,7.9,5.99,7.83,6.19,7.83c0.19,0,0.38,0.07,0.52,0.21l0.23,0.21
                    c0,0,0.23,0.35,0.23,0.55c0,0.15-0.05,0.3-0.14,0.43c-0.01,0.01-2.46,2.57-2.46,2.57c-0.13,0.09-0.28,0.14-0.44,0.14
                    c-0.19,0-0.38-0.07-0.52-0.21L3.38,11.51z M5.41,14.54c-0.19,0-0.38-0.07-0.52-0.21l-0.23-0.21h0c-0.16-0.15-0.23-0.34-0.23-0.55
                    c0-0.19,0.07-0.38,0.21-0.52c0,0,3.04-3.17,3.07-3.2c0.14-0.14,0.33-0.21,0.53-0.21c0.19,0,0.38,0.07,0.52,0.21l0.23,0.22
                    c0.16,0.15,0.23,0.35,0.23,0.55c0,0.19-0.07,0.38-0.21,0.52l-3.05,3.18C5.81,14.47,5.61,14.54,5.41,14.54L5.41,14.54z M7.9,15.89
                    c-0.19,0-0.38-0.07-0.52-0.21l-0.23-0.21C7,15.32,6.92,15.12,6.92,14.92c0-0.17,0.06-0.33,0.17-0.47l2.12-2.21
                    c0.14-0.13,0.32-0.2,0.51-0.2c0.19,0,0.38,0.07,0.52,0.21l0.23,0.22c0.16,0.15,0.23,0.34,0.23,0.55c0,0.19-0.07,0.38-0.21,0.52
                    l-2.04,2.13C8.31,15.81,8.11,15.89,7.9,15.89L7.9,15.89z M11.2,16.77C11.05,16.92,10.85,17,10.64,17c-0.19,0-0.38-0.07-0.52-0.21
                    l-0.23-0.21c-0.16-0.15-0.23-0.35-0.23-0.55c0-0.19,0.07-0.38,0.21-0.52l1.29-1.34c0.15-0.15,0.35-0.23,0.55-0.23
                    c0.19,0,0.38,0.07,0.53,0.21l0.23,0.22c0.16,0.15,0.23,0.35,0.23,0.55c0,0.19-0.07,0.38-0.21,0.52L11.2,16.77z M23.45,12.3
                    l-0.21,0.23c-0.15,0.16-0.35,0.24-0.56,0.24c-0.19,0-0.37-0.07-0.52-0.2l-3.59-3.32l-0.62,0.66l3.84,3.56
                    c0.16,0.15,0.24,0.35,0.24,0.55c0,0.19-0.07,0.37-0.2,0.51l-0.21,0.23c-0.15,0.16-0.35,0.24-0.56,0.24c-0.19,0-0.37-0.07-0.52-0.2
                    l-3.59-3.32l-0.62,0.67l3.19,2.95c0.15,0.15,0.23,0.34,0.23,0.54c0,0.19-0.07,0.37-0.2,0.51l-0.21,0.23
                    c-0.15,0.16-0.35,0.24-0.56,0.24c-0.19,0-0.37-0.07-0.52-0.2l-3.18-2.94h0h0l-0.62,0.66l0,0l0.01,0.01l1.59,1.47
                    c0.14,0.14,0.21,0.33,0.21,0.52c0,0.19-0.07,0.37-0.2,0.51l-0.21,0.23c-0.15,0.16-0.35,0.24-0.56,0.24c-0.19,0-0.37-0.07-0.52-0.2
                    l-1.39-1.29c0.12-0.24,0.19-0.5,0.19-0.75c0-0.44-0.17-0.88-0.52-1.2l-0.23-0.22c-0.32-0.31-0.74-0.46-1.15-0.46
                    c-0.03,0-0.06,0.01-0.1,0.01c0-0.01,0-0.02,0-0.02c0-0.44-0.52-1.21-0.52-1.21l-0.23-0.22c-0.24-0.23-0.53-0.37-0.84-0.43
                    c0.06-0.18,0.1-0.36,0.1-0.55c0-0.44-0.17-0.88-0.52-1.21L9.38,9.19C9.06,8.88,8.64,8.73,8.23,8.73c-0.05,0-0.1,0.01-0.14,0.01
                    C8.07,8.33,7.9,7.91,7.57,7.59l0,0L7.35,7.38C7.02,7.07,6.61,6.92,6.19,6.92c-0.44,0-0.88,0.17-1.21,0.51L4.44,8L2.06,5.96
                    C1.88,5.81,1.8,5.61,1.8,5.4c0-0.17,0.06-0.35,0.19-0.49L4.5,2.09c0.1-0.11,0.25-0.21,0.42-0.28C5.1,1.74,5.29,1.7,5.48,1.7
                    c0.16,0,0.31,0.03,0.43,0.08l1.38,0.59C7.68,2.53,8.14,2.6,8.59,2.6c0.46,0,0.91-0.07,1.3-0.23l1.38-0.59
                    c0.21-0.09,0.54-0.15,0.85-0.15c0.35,0,0.71,0.07,0.91,0.18c0.13,0.07,0.25,0.13,0.37,0.18L12,2.98c-0.45,0.32-0.7,0.83-0.7,1.34
                    c0,0.34,0.11,0.68,0.33,0.98l0,0l0.19,0.25c0.35,0.47,0.91,0.71,1.46,0.71c0.31,0,0.63-0.08,0.91-0.25l0.84-0.51
                    c0.17-0.11,0.43-0.17,0.7-0.17c0.31,0,0.63,0.09,0.82,0.23l2.33,1.67c0.52,0.37,1.37,1.06,1.83,1.5l2.7,2.5
                    c0.16,0.15,0.24,0.35,0.24,0.56C23.66,11.97,23.59,12.15,23.45,12.3L23.45,12.3z M26.57,5.6l-3.6,3.98l-1.63-1.51
                    c-0.51-0.47-1.35-1.16-1.92-1.57l-2.33-1.67c-0.39-0.28-0.88-0.4-1.36-0.4c-0.41,0-0.82,0.09-1.17,0.3l-0.84,0.51
                    c-0.13,0.08-0.28,0.12-0.44,0.12c-0.28,0-0.57-0.13-0.72-0.35l-0.19-0.25l0,0c-0.1-0.13-0.15-0.28-0.15-0.43
                    c0-0.23,0.11-0.45,0.31-0.6l2.49-1.75c0.21-0.16,0.6-0.27,0.96-0.26c0.25,0,0.5,0.05,0.66,0.13l2.03,0.98
                    c0.35,0.17,0.74,0.23,1.13,0.24c0.5,0,1.01-0.11,1.43-0.37l2.07-1.29c0.13-0.08,0.29-0.13,0.46-0.13c0.28,0,0.56,0.13,0.72,0.33
                    l2.13,2.72c0.12,0.16,0.19,0.38,0.19,0.6C26.81,5.18,26.71,5.43,26.57,5.6L26.57,5.6z"/>
        </g>
    </svg>

    );
};

export default Partner;