import React from 'react';

const SlashVertical = ({mainColor}) => {
    return (
        <svg width="34" height="24"
             viewBox="0 0 34 24"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" fill={mainColor} d="M12.8497 23.8471L34 0.0335076L21.2381 0L0 24L12.8497 23.8471Z" />
        </svg>
    );
};

export default SlashVertical;