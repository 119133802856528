import React,{Fragment,useCallback,useState} from 'react';
import { Redirect } from "react-router";

//svg
import Info from "../../../../../../svg/Info";
import Print from "../../../../../../svg/Print";
import Partner from "../../../../../../svg/Partner";

//config
import {config} from "../../../../../../../config";

//components

const Description = (
    {
        thumb,
        name,
        id,
        idCat,
        description,
        specs,
        features,
        locales,
        pdf,
        isPartner
    }) => {

    const [redirectUrl,setRedirectUrl] = useState({
        cat:null,
        item:null
    });

    const handleServiceRedirection = useCallback( (e,id,idCat) => {
        e.preventDefault();
        setRedirectUrl({
            cat: idCat,
            item: id
        });
    },[
        setRedirectUrl
    ])
    return (
        <Fragment>
            {
                redirectUrl.item &&
                <Redirect
                    push
                    to={`${config.domainRelPath}/${redirectUrl.cat}/${redirectUrl.item}`}
                />
            }
            <div
                className="col col-xs-12 col-sm-4 services__category__item__info"
                style={{
                    backgroundColor: isPartner ? "#a7a7a7" : "#21499A"
                }}
            >
                <div className="row services__category__item__info__thumb">
                    <div className="col col-xs-12">
                        <img
                            src={thumb}
                            alt={`Pic: ${name}`}
                            width={418}
                            height={259} />
                        {
                            isPartner &&
                            <p className="services__category__item__info__thumb__partner">{locales.partner}</p>
                        }

                    </div>
                </div>

                <div className="row">
                    <div className="col col-xs-12 services__category__item__info__title">
                        <h4 onClick={ e => handleServiceRedirection(e,id,idCat)}>{name}</h4>
                        <nav>
                            <ul>
                                {
                                    isPartner &&
                                    <li title="partner">
                                        <Partner mainColor="#FFFFFF" />
                                    </li>
                                }
                                <li>
                                    <a
                                        href="#"
                                        onClick={ e => handleServiceRedirection(e,id,idCat)}
                                    >
                                        <Info mainColor="#FFFFFF" />
                                    </a>
                                </li>
                                {
                                    pdf &&
                                    <li>
                                        <a href={pdf ? pdf : "#"} target="_blank">
                                            <Print mainColor="#FFFFFF" />
                                        </a>
                                    </li>
                                }

                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12 services__category__item__info__description">
                        <p dangerouslySetInnerHTML={{__html:description}}/>
                        <span onClick={ e => handleServiceRedirection(e,id,idCat)}>
                            {locales.more}
                        </span>
                    </div>
                </div>
                <div className={`row services__category__item__info__specs ${isPartner ? "services__category__item__info__specs__partner" : ""}`}>
                    <div className="col col-xs-5">
                        <h5>{locales.specs}</h5>
                        <article dangerouslySetInnerHTML={{__html:specs}} />
                    </div>
                    <div className="col col-xs-7">
                        <h5>{locales.features}</h5>
                        <article dangerouslySetInnerHTML={{__html:features}} />
                        <span onClick={ e => handleServiceRedirection(e,id,idCat)}>
                            {locales.fullInfo}
                        </span>
                    </div>
                </div>
            </div>
        </Fragment>

    );
};

export default Description;