import React from 'react';
import { connect } from "react-redux";

const ContactEmails = ({pageOptions}) => {
    return (
        <div className="row contact__data contact__data__emails center-xs">
            <div className="col col-xs-12 contact__data__values">
            {
                pageOptions.opt_kontakt_maile &&
                pageOptions.opt_kontakt_maile.map(
                    (email,index) =>
                        <a
                            key={index}
                            href={`mailto:${email.opt_email}`} title={`Email: ${email.opt_email_nazwa}`}
                        >
                            {email.opt_email}
                        </a>
                )
            }
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    pageOptions: state.page.pageOptions
});

export default connect(mapStateToProps,null)(ContactEmails);