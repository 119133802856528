import React,{useState,useEffect} from 'react';
import { connect } from "react-redux";

//components
import TopBar from "./TopBar";
import Description from "./Description";
import ServiceGallery from "./ServiceGallery";

const Category = (
    {
        servicesMainTitle,
        category,
        accentColor,
        pageOptions,
        serviceSelected
    }) => {

    const [ activeItem, setActiveItem ] = useState(0);
    const [ activeItemID, setActiveItemID ] = useState(null);

    useEffect( () => {
        if ( serviceSelected ) {
            setActiveItemID(category.id_kategorii === serviceSelected.category ? serviceSelected.filter : null)
        }
    },[
        serviceSelected,
        setActiveItemID
    ])



    return (
        <div id={category.id_kategorii}>
            <TopBar
                servicesMainTitle={servicesMainTitle}
                accentColor={accentColor}
                category={category}
                setActiveItem={setActiveItem}
                activeItem={activeItem}
                setActiveItemID={setActiveItemID}
                activeItemID={activeItemID}
                />
            {
                category.lista
                    .filter( (item,index) => activeItemID ? item.id_pozycji === activeItemID : index===activeItem )
                    .map( (item,index) =>
                        <div
                            key={index}
                            className={`row services__category__item ${item.pozycja_partner ? "services__category__item__partner" : ""}`}>
                            <Description
                                thumb={item.pozycja_miniatura}
                                name={item.nazwa_pozycji}
                                id={item.id_pozycji}
                                idCat={category.id_kategorii}
                                description={item.pozycja_opis}
                                specs={item.pozycja_specs}
                                features={item.pozycja_features}
                                isPartner={item.pozycja_partner}
                                locales={{
                                    specs: pageOptions.loc_specyfikacje,
                                    features: pageOptions.loc_funkcje,
                                    fullInfo: pageOptions.loc_full_info,
                                    more: pageOptions.loc_wiecej,
                                    partner: pageOptions.loc_partner
                                }}
                                pdf={item.pozycja_pdf}
                                />
                            <ServiceGallery
                                accentColor={accentColor}
                                gallery={item.pozycja_galeria}
                            />
                        </div>
                )
            }
        </div>

    );
};

const mapStateToProps = state => ({
    accentColor: state.page.pageOptions.opt_akcent_glowny,
    pageOptions: state.page.pageOptions,
    serviceSelected: state.page.serviceSelected
});


export default connect(mapStateToProps,null)(Category);