import React from 'react';

const Arrow = ({mainColor}) => {
    return (
        <svg width="20" height="51" viewBox="0 0 20 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5 1L1 26L19.5 50" stroke={mainColor} />
        </svg>

    );
};

export default Arrow;