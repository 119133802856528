import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";

// components
import Page from '../../Page';
import Footer from "../../Footer";
import SectionAboutUs from "./Sections/SectionAboutUs";
import Services from "./Sections/Services";
import HomeGallery from "./Sections/HomeGallery";
import Portfolio from "./Sections/Portfolio";

//tools
import MediaQuery from 'react-responsive';

//config
import { config } from "../../../config";

const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

const Home = ({
          pageData,
          pageOptions
      }) => {

    const [scrollY, setScrollY] = useState(false);

    useEffect(() => {

        const handleScroll = () => {
            const currentScrollY = window.scrollY/1.7;
            setScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [setScrollY]);

        return (
            <Page pageIndex={config.pageIndex.home}>

                <main className="page home boxed" >

                    <SectionAboutUs
                        cover={pageData.home_onas_obraz_tla}
                        coverMobile={pageData.home_onas_obraz_tla_mobile}
                        coverMobileTransp={pageData.home_tools_onas_tranparent}
                        blend={pageData.home_onas_kolor_blendy}
                        txt1={pageData.home_onas_tekst1}
                        txt2={pageData.home_onas_tekst2}
                        txtPosition={pageData.home_onas_pozycja_tekstu}
                        txtColor={pageData.home_onas_kolor_tekstu}
                        isMobile={isMobile}
                    />

                    <Services
                        servicesMainTitle={pageData.services_tytul}
                        servicesMenuID={pageData.services_menu_id}
                        list={pageData.services_lista_kategorii}
                    />

                    <HomeGallery
                        arrowColor={pageData.home_galeria_arrow}
                        galleryTitle={pageData.home_galeria_tytul}
                        galleryMenuID={pageData.home_menu_id}
                        blend={pageData.home_onas_kolor_blendy}
                        isBlended={pageData.home_galeria_blend}
                        homeGallery={pageData.home_galeria}
                    />

                    <Portfolio
                        portfolioTitle={pageData.portfolio_tytul}
                        portfolioMenuID={pageData.portfolio_menu_id}
                        list={pageData.kategorie}
                    />



                </main>

                <Footer />

            </Page>
        );

}

const mapStateToProps = (state) => ({
    pageData: state.page.pageData,
    pageOptions: state.page.pageOptions,
    pageOptionsPortfolio: state.page.pageOptionsPortfolio,
});


export default connect(mapStateToProps,null)(Home);

