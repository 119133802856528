import React,{Fragment} from 'react';

//tools
import Slider from "react-slick";
import NextSectionNav from "../../NextSectionNav";

//svg
import Arrow from "../../../../svg/Arrow";

const HomeGallery = (
    {
        galleryMenuID,
        galleryTitle,
        homeGallery,
        arrowColor,
        isBlended,
        blend
    }) => {

    const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
        <span {...props}>{children}</span>
    );

    const sliderSettings = {
        autoplay:true,
        autoplaySpeed: 5000,
        cssEase:'ease-in-out',
        dots: false,
        fade:true,
        arrows: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        initialSlide:0,
        nextArrow: <SlickButtonFix><div className="arrow next"><Arrow mainColor={arrowColor} /></div></SlickButtonFix>,
        prevArrow: <SlickButtonFix><div className="arrow prev"><Arrow mainColor={arrowColor} /></div></SlickButtonFix>
    };

    return (
        <Fragment>
            <NextSectionNav
                nextSectionID={galleryMenuID}
                nextSectionName={galleryTitle}
                scrollOffset={30}
            />
            <div
                id={galleryMenuID}
                className="row gallery"
            >
                <div className="col col-xs-12">
                    {
                        isBlended &&
                        <div
                            className="gallery__blend"
                            style={{backgroundColor: blend}}
                        />
                    }
                    <h2>{galleryTitle}</h2>
                    <Slider {...sliderSettings} >
                        {
                            homeGallery &&
                            homeGallery.map( (img,index) =>
                                <img
                                    key={index}
                                    src={img.home_galeria_img}
                                    width={1280}
                                    height={720}
                                    alt={img.home_galeria_alt}
                                />
                            )
                        }
                    </Slider>
                </div>
            </div>
        </Fragment>

    );
};

export default HomeGallery;