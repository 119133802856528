import React from 'react';

const MenuClose = ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_close"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="20.667px" height="20.667px" viewBox="0 0 20.667 20.667" >
            <path fill={mainColor} d="M20.667,17.963L2.703,0L0,2.703l17.963,17.964L20.667,17.963z"/>
            <path fill={mainColor} d="M17.963,0L0,17.964l2.703,2.703L20.667,2.703L17.963,0z"/>
        </svg>
    );
};

export default MenuClose;