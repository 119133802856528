import React, {Fragment,useCallback,useEffect} from 'react';
import { connect } from "react-redux";

//actions
import {
    setHoveredMenuItemIndex,
    setService,
    toggleHomeMenu
} from "../../../../actions/pageActions";

//tools
import { handleMenuHash } from "../menuUtils";
import classnames from "classnames";

import {config} from "../../../../config";


const MenuTopSubmenu = (
    {
        menu,
        parentItem,
        setHoveredMenuItem,
        focusedMenuItem,
        isMobile,
        toggleMenu,
        setService,
        post
    }) => {

    const handleWindowScroll = useCallback( isMobile => {
        console.log("handleWindowScroll");
    },[]);

    useEffect( () => {

        !isMobile ?
            window.addEventListener('scroll', handleWindowScroll(isMobile)) :
            toggleMenu(false);

        return () => {
            !isMobile ?
                window.removeEventListener('scroll', handleWindowScroll):
                toggleMenu(false)
        }
    },[toggleMenu]);

    const submenu =  menu.filter( item => {
                        return item.menu_item_parent === parentItem.object_id ||
                            item.menu_item_parent === parentItem.db_id.toString();
                    });

    return (
        <Fragment>
            {
                submenu.length > 0 &&
                <div>
                    <ul className="nav-mainmenu__submenu row">
                        {
                            submenu
                                .map( item =>
                                    <li
                                        key={item.ID}
                                        data-parent-id={parentItem.object_id}
                                        data-child-id={item.object_id}
                                        className={classnames(
                                            "nav-mainmenu__submenu__item col-xs-12 col-sm-6 col-md-4  col-lg-3", {
                                                "active" : item.ID == focusedMenuItem
                                            }
                                        )}
                                    >
                                        <a
                                            href={item.url}
                                            onClick={ e => {
                                                console.log(item);
                                                handleMenuHash(
                                                config.domain + config.domainRelPath,
                                                e,
                                                item.url,
                                                post,
                                                toggleMenu,
                                                item.post_name,
                                                setService
                                            )}}
                                            onMouseEnter={ () => setHoveredMenuItem(item.ID)  }
                                        >
                                            {item.title}
                                        </a>
                                        {
                                            menu
                                                .filter( grandchildItem => {
                                                    return grandchildItem.menu_item_parent === item.object_id ||
                                                        grandchildItem.menu_item_parent === item.db_id.toString();
                                                })
                                                .length > 0 &&
                                            <div>
                                                <ul>
                                                    {
                                                        menu
                                                            .filter( grandchildItem => {
                                                                return grandchildItem.menu_item_parent === item.object_id ||
                                                                    grandchildItem.menu_item_parent === item.db_id.toString();
                                                            })
                                                            .map( grandchildItem =>
                                                                <li
                                                                    key={grandchildItem.ID}
                                                                    data-parent-id={item.object_id}
                                                                    data-child-id={grandchildItem.object_id}
                                                                    className={classnames(
                                                                        "nav-mainmenu__submenu__item", {
                                                                            "active" : grandchildItem.ID == focusedMenuItem
                                                                        }
                                                                    )}
                                                                >
                                                                    <a
                                                                        href={grandchildItem.url}
                                                                        onClick={ e => {
                                                                            console.log(grandchildItem);
                                                                            handleMenuHash(
                                                                                config.domain,
                                                                                e,
                                                                                grandchildItem.url,
                                                                                post,
                                                                                toggleMenu,
                                                                                item.post_name,
                                                                                setService
                                                                            )
                                                                        } }
                                                                        onMouseEnter={ () => setHoveredMenuItem(grandchildItem.ID)  }
                                                                    >
                                                                        {grandchildItem.title}
                                                                    </a>
                                                                </li>

                                                            )
                                                    }
                                                </ul>
                                            </div>

                                        }


                                    </li>
                                )
                        }
                    </ul>
                </div>
            }
        </Fragment>

    );
};

const mapStateToProps = state => ({
    focusedMenuItem: state.page.focusedMenuItem,
    pageOptions: state.page.pageOptions,
    menuHomeExpanded: state.page.menuHomeExpanded
});

const mapDispatchToProps = dispatch => ({
    toggleMenu: value => dispatch( toggleHomeMenu(value) ),
    setHoveredMenuItem: value => dispatch( setHoveredMenuItemIndex (value)),
    setService: data => dispatch( setService(data) )
});


export default connect(mapStateToProps,mapDispatchToProps)(MenuTopSubmenu);