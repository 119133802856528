import React, {Fragment} from 'react';

//components
import NextSectionNav from "../../NextSectionNav";
import PortfolioTopBar from "./PortfolioTopBar";
import PortfolioCategory from "./PortfolioCategory";

const Portfolio = (
    {
        portfolioTitle,
        portfolioMenuID,
        list
    }) => {
    return (
        <Fragment>
            <NextSectionNav
                nextSectionID={portfolioMenuID}
                nextSectionName={portfolioTitle}
                scrollOffset={-140}
                slash
            />
            <PortfolioTopBar
                id={portfolioMenuID}
                categories={list} />
            <div className="row portfolio center-xs start-sm" >

                {
                    list &&
                    list.map( (category,index) =>
                        <PortfolioCategory
                            category={category}
                            key={index} />
                    )

                }

            </div>
        </Fragment>
    );
};

export default Portfolio;