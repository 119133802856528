import React from 'react';

//tools
import Slider from "react-slick";

//svg
import Arrow from "../../../../../../svg/Arrow";

const ServiceGallery = (
    {
        accentColor,
        gallery,
        post
    }) => {

    const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
        <span {...props}>{children}</span>
    );

    const sliderSettings = {
        autoplay:false,
        cssEase:'ease-in-out',
        dots: false,
        arrows: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        initialSlide:0,
        nextArrow: <SlickButtonFix><div className="arrow next"><Arrow mainColor={accentColor} /></div></SlickButtonFix>,
        prevArrow: <SlickButtonFix><div className="arrow prev"><Arrow mainColor={accentColor} /></div></SlickButtonFix>
    };


    return (
        <div className={`col col-xs-12 col-sm-${post ? "6" : "8"} services__category__item__gallery`}>
            <Slider {...sliderSettings} >
            {
                gallery &&
                gallery.map( (img,index) =>
                    <img
                        key={index}
                        src={img.pozycja_galeria_obraz}
                        width={928}
                        height={641}
                        alt={img.pozycja_galeria_tytul_obrazu}
                    />
                )
            }
            </Slider>
        </div>
    );
};

export default ServiceGallery;