import React, {useCallback} from 'react';
import {connect} from "react-redux";

//config
import {config} from "../../../../config";

//svg
import ArrowHorizontal from "../../../svg/ArrowHorizontal";
import SlashVertical from "../../../svg/SlashVertical";

//tools
import {InView} from "react-intersection-observer";

const NextSectionNav = (
    {
        nextSectionID,
        nextSectionName,
        scrollOffset,
        slash,
        accentColor
    }) => {

    const scrollToHash = useCallback( (e,nextSectionID) => {
        e.preventDefault();
        const el = document.getElementById(nextSectionID);
        const posY = el.offsetTop;
        window.scrollTo({
            top: posY + scrollOffset,
            left:0,
            behavior: "smooth"
        });
    },[scrollOffset]);

    return (
        <InView>
            {({ inView, ref }) => (
                <div
                    ref={ref}
                    className="next-section-nav row center-xs"
                >
                    <div className="col col-xs-12">
                        {
                            slash &&
                            <div className="next-section-nav__slash">
                                <SlashVertical mainColor={accentColor} />
                            </div>
                        }
                        <a
                            href={config.domainRelPath + "/#" + nextSectionID}
                            className={inView ? "bounce" : ""}
                            onClick={ e => scrollToHash(e,nextSectionID)}
                        >
                            <span>{nextSectionName}</span>
                            <ArrowHorizontal mainColor={accentColor} />
                        </a>
                    </div>
                </div>
            )}
        </InView>
    );
};

const mapStateToProps = (state) => ({
    accentColor: state.page.pageOptions.opt_akcent_glowny
});


export default connect(mapStateToProps,null)(NextSectionNav);