import React, {Fragment,useCallback,useEffect} from "react";
import { connect } from "react-redux";

//actions
import {setPageOptions, toggleHomeMenu} from "../../../actions/pageActions";
import { setHoveredMenuItemIndex } from "../../../actions/pageActions";

//components
import MenuTopSubmenu from "./MenuTopSubmenu";

//tools
import { handleMenuHash } from "./menuUtils";
import classnames from "classnames";
import MediaQuery from 'react-responsive';

//svg
import MenuClose from "../../svg/MenuClose";
import MenuOpen from "../../svg/MenuOpen";
import MenuTopLink from "./MenuTopLink";


const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

const MenuTop = (
    {
        toggleMenu,
        setHoveredMenuItem,
        menuHomeExpanded,
        focusedMenuItem,
        pageOptions,
        post,
        menu
    }) => {

    const handleWindowScroll = useCallback( isMobile => {
        console.log("handleWindowScroll");
    },[]);

    useEffect( () => {

        !isMobile ?
            window.addEventListener('scroll', handleWindowScroll(isMobile)) :
            toggleMenu(false);

        return () => {
            !isMobile ?
                window.removeEventListener('scroll', handleWindowScroll):
                toggleMenu(false)
        }
    },[toggleMenu]);

    return (
        <Fragment>
            <MediaQuery maxWidth={768}>
                {
                    menuHomeExpanded ?
                        <div className="mobile mobile-menu-btn close"
                             onClick={()=>toggleMenu(false)}>
                            <MenuClose mainColor={pageOptions.opt_akcent_glowny}/>
                        </div> :
                        <div className="mobile mobile-menu-btn open"
                             onClick={()=>toggleMenu(true)}>
                            <MenuOpen mainColor={pageOptions.opt_akcent_glowny}/>
                        </div>
                }
            </MediaQuery>

            <nav className={classnames(
                "menu-top",
                { "expanded" : menuHomeExpanded }
            )}>
                <ul className="nav-mainmenu">
                    {
                        menu
                            .filter(
                                item => {
                                    return item.post_parent == 0 && item.menu_item_parent == 0;
                                }
                            )
                            .map(item =>
                                <li
                                    key={item.url}
                                    data-id={item.object_id}
                                    className={classnames(
                                        "nav-mainmenu__menu__item", {
                                            "active" : item.ID == focusedMenuItem
                                        }
                                    )}
                                >
                                    <MenuTopLink
                                        post={post}
                                        setHoveredMenuItem={setHoveredMenuItem}
                                        toggleMenu={toggleMenu}
                                        item={item}
                                        accentColor={pageOptions.opt_akcent_glowny}
                                        displayDoodle={item.ID == focusedMenuItem}
                                        />

                                    <MenuTopSubmenu
                                        post={post}
                                        isMobile={isMobile}
                                        menu={menu}
                                        parentItem={item} />

                                </li>
                            )}
                </ul>
            </nav>

        </Fragment>
    );
};


const mapStateToProps = state => ({
    focusedMenuItem: state.page.focusedMenuItem,
    pageOptions: state.page.pageOptions,
    menuHomeExpanded: state.page.menuHomeExpanded
});

const mapDispatchToProps = dispatch => ({
    toggleMenu: value => dispatch( toggleHomeMenu(value) ),
    setHoveredMenuItem: value => dispatch( setHoveredMenuItemIndex (value))
});


export default connect(mapStateToProps,mapDispatchToProps)(MenuTop);