import {
    TOGGLE_OVERLAY,
    TOGGLE_LOADER,
    TOGGLE_HOME_MENU,
    SET_PAGE_DATA,
    SET_HOVERED_MENU_ITEM,
    SET_PAGE_OPTIONS,
    SET_PAGE_OPTIONS_PORTFOLIO,
    SET_SERVICE,
    TOGGLE_HOME_LOGO_OVERLAY
} from "../actions/pageActions";

const defaultState = {
    displayOverlay: false,
    displayLoader: true,
    displayHomeLogoOverlay: true,
    pageData: {},
    focusedMenuItem: null,
    pageOptions: {},
    pageOptionsPortfolio: {},
    menuHomeExpanded:false,
    serviceSelected: null
};

const page = (state = defaultState, action = undefined) => {
    const {type} = action;

    switch (type) {
        case TOGGLE_HOME_MENU: {
            return {
                ...state,
                menuHomeExpanded: action.value
            }
        }
        case TOGGLE_OVERLAY: {
            return {
                ...state,
                displayOverlay: action.value
            }
        }
        case TOGGLE_LOADER: {
            return {
                ...state,
                displayLoader: action.value
            }
        }

        case TOGGLE_HOME_LOGO_OVERLAY: {
            return {
                ...state,
                displayHomeLogoOverlay: action.value
            }
        }
        case SET_PAGE_DATA: {
            return {
                ...state,
                pageData: action.data
            }
        }
        case SET_HOVERED_MENU_ITEM: {
            return {
                ...state,
                focusedMenuItem: action.value
            }
        }
        case SET_PAGE_OPTIONS: {
            return {
                ...state,
                pageOptions: action.data
            }
        }
        case SET_PAGE_OPTIONS_PORTFOLIO: {
            return {
                ...state,
                pageOptionsPortfolio: action.data
            }
        }
        case SET_SERVICE: {
            return {
                ...state,
                serviceSelected: action.data
            }
        }
        default:
            return state;
    }
};

export default page;