import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {ConnectedRouter} from "connected-react-router";
import routes from './js/routes';

//actions
import {toggleOverlay} from "./js/actions/pageActions";

// components
import Loading from "./js/components/Loading";

class App extends Component {

    constructor(props) {
        super(props);
        this.handleOverlay = this.handleOverlay.bind(this);
    }

    handleOverlay() {
        this.props.showOverlay(true);
    }

    render() {

        const { history } = this.props;

        history.listen(location => {
            this.handleOverlay()
        });

        return (
            <div className="App">
                <ConnectedRouter history={history}>
                    { routes }
                </ConnectedRouter>
            </div>
        );
    }
}

App.propTypes = {
    history: PropTypes.object,
};

const mapStateToProps = (state) => ({
    displayOverlay: state.page.displayOverlay,
    location: state.router.location
});

const mapDispatchToProps = dispatch => ({
    showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(App);
