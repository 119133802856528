import React, {useState,useEffect} from 'react';
import { connect } from "react-redux";
import axios from "axios";

//actions
import {
    setHoveredMenuItemIndex,
    toggleHomeLogoOverlay,
    toggleHomeMenu} from "../../actions/pageActions";

// components
import MenuTop from '../Menu/MenuTop';
import ContactPhones from "../ContactPhones";
import ContactEmails from "../ContactEmails";
import LanguageSwitch from "../LanguageSwitch";

//tools
import classnames from "classnames";

//config
import { config } from "../../config";

const Header = (
    {
        url,
        post,
        menuHomeExpanded,
        pageOptions
    }) => {

    const [headerState,setHeaderState] = useState({
        burgerHovered:false,
        menu:[]
    });

    useEffect( () => {
        const menuNode = post ? "menu" : "menu";
        axios
            .get(
                config.domainRelPath+'/wp-json/api/' + menuNode
            )
            .then(res => {
                setHeaderState({
                    ...headerState,
                    menu: res.data
                });
            })
            .catch(error => console.log(error));
    },[setHeaderState]);

    return (
        <header className={classnames(
            "main-header boxed", {
                "expanded" : menuHomeExpanded
            }

        )} >
            <div className="row">
                <div className="col col-xs-4 col-sm-2">
                    <h1>
                        <a href={config.domain + config.domainRelPath} title="Link: Home">
                            <img
                                src={pageOptions.opt_logo}
                                alt="Baseone"
                            />
                        </a>
                    </h1>
                </div>
                <div className="col col-xs-12 col-sm-6">
                    <MenuTop role="menu"
                             post={post}
                             url={url}
                             menu={headerState.menu}
                             expanded={menuHomeExpanded} />
                </div>
                <div className="col col-xs-12 col-sm-4">
                    <div className="row">
                        <div className="col col-xs-12 col-sm-8">
                            <ContactPhones />
                        </div>
                        <div className="col col-xs-12 col-sm-4">
                            <ContactEmails />
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
};

const mapStateToProps = state => ({
    menuHomeExpanded: state.page.menuHomeExpanded,
    pageOptions: state.page.pageOptions
});

const mapDispatchToProps = dispatch => ({
    setHomeLogoOverlay: value => dispatch(toggleHomeLogoOverlay(value)),
    setHoveredMenuItem: value => dispatch( setHoveredMenuItemIndex (value)),
    toggleMenu: value => dispatch( toggleHomeMenu(value) ),
});


export default connect(mapStateToProps,mapDispatchToProps)(Header);

