import React from 'react';

const MenuOpen= ({mainColor}) => {
    return (
        <svg version="1.1" id="svg_open" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px"
             width="100.45px" height="67.76px"
             viewBox="3.25 1.75 100.45 67.76">
            <path fill={mainColor} d="M103.7,1.75H3.25v15.11H103.7V1.75z"/>
            <path fill={mainColor} d="M103.7,28.33H3.25v15.11H103.7V28.33z"/>
            <path fill={mainColor} d="M103.7,54.4H3.25v15.11H103.7V54.4z"/>
        </svg>
    );
};

export default MenuOpen;